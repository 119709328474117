import AboutVision from '../../asserts/AboutVision.webp'
import AboutMission from '../../asserts/AboutMission.webp'

export const AboutCompany = () => {
    return(
        <div className="AboutCompany">
           <div className="vision py-lg-5 py-4">
                <div className="container">
                    <h2 className="text-dark text-center mb-5 d-none d-lg-block">About Our Compnay</h2>
                    <div className="row mb-4 d-flex align-items-center">
                        <div className="col-12 col-md-6">
                            <h2 className="text-dark">Company Vision</h2>
                            <p className="p text-dark">At EgeneusCo, we envision a future where technology knows no limits. We're pioneers of transformative solutions, committed to crafting a world where every business, regardless of size, shines in the digital landscape. Our mission is to empower entrepreneurs and organizations to reach new heights, unencumbered by the constraints of traditional thinking.</p> 
                        </div>
                        <div className="col-12 col-md-6">
                            <div className="image-container position-relative overflow-hidden rounded shadow" style={{ height: '400px'}}>
                                <img src={AboutVision} alt="" className="w-100" width="100%" height="100%" />
                            </div>
                        </div>
                    </div>
                </div>
           </div>
            <div className="Mission bg-dark py-lg-5 py-4">
                <div className="container">
                    <div className="row d-flex align-items-center">
                        <div className="col-12 col-md-6 mb-4">
                            <div className="image-container position-relative overflow-hidden rounded shadow" style={{ height: '400px'}}>
                                <img src={AboutMission} alt="" className="" width="100%" height="100%" />
                            </div>
                        </div>
                        <div className="col-12 col-md-6">
                            <h2 className="text-white">Company Mission</h2>
                            <p className="p">At EgeneusCo, our mission is to guide you through the complexities of the digital realm, effortlessly. We're more than a development company – we're a catalyst for growth, providing cost-effective, innovative solutions that propel startups to new heights and empower businesses to break through boundaries. Our expertise helps you transcend limitations, achieve scalability, and thrive in the ever-evolving digital landscape.</p> 
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}