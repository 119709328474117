import Carousel1 from "../../asserts/meet-team2.webp"
import Carousel2 from "../../asserts/meet-team3.webp"
import Carousel3 from "../../asserts/meet-team4.webp"
import Carousel4 from "../../asserts/meet-team5.webp"

export const TeamsItems = [
    {
        id:'MOHAMMADHUSSNAIN',
        image: Carousel4,
        Name: 'HUSSNAIN IMRAN', 
        Major: 'Web Developer',
        Experience: '7 Year Experience',
        Profile:'',
        Email:'Dev@egeneus.co',
        buttonText: 'View Profile',
        buttonLink: ''
    },
    {
        id:'AYESHAMIRAZA',
        image: Carousel1,
        Name: 'AYESHA MIRAZA', 
        Major: 'Content Writer',
        Experience: '10 Year Experience',
        Profile:'',
        Email:'AyeshaMirza@egeneus.co',
        buttonText: 'View Profile',
        buttonLink: ''
    },
    {
        id:'NAZIMALI',
        image: Carousel3,
        Name: 'NAZIM ALI', 
        Major: 'Social Media Expert',
        Experience: '8 Year Experience',
        Profile:'',
        Email:'nazimali@egeneus.co',
        buttonText: 'View Profile',
        buttonLink: ''
    },
    {
        id:'UMARFAROOQ',
        image: Carousel2,
        Name: 'UMAR FAROOQ', 
        Major: 'SEO Expert',
        Experience: '9 Year Experience',
        Profile:'',
        Email:'umarfarooq@egeneus.co',
        buttonText: 'View Profile',
        buttonLink: ''
    }

  ];
  
  
  
  
  
  
  