import { Link } from "react-router-dom";
import {TeamsItems} from '../Items/TeamsItems'
export const AboutTeam = () => {
  return (

    <div id="About-team">
        <div className="master-team-section bg-dark py-4 py-lg-5">
            <div className="container">
                <div className="master-team-container">
                    <div className="row">
                        <div className="col-12 col-lg-6 text-center d-flex justify-content-center align-items-center login-box mb-4">
                            <div>
                                <h2 className="h2 mb-4 w-75 m-auto">MEET OUR MASTERS TEAM</h2>
                                <div className="service-button-container m-auto p-1">
                                    <Link to="/" className="link text-decoration-none text-white anim-button w-75 m-auto py-3 ">
                                        <span></span>
                                        <span></span>
                                        <span></span>
                                        <span></span>
                                        View More
                                    </Link>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-lg-6">
                            <div className="row">
                                {
                                    TeamsItems.length > 0 ? (
                                    TeamsItems.slice(0, 6).map((data, index) => (
                                        <div key={index} className="col-6 mb-3">
                                            <div className="master-image-container position-relative overflow-hidden rounded shadow">
                                                <div className="position-relative overflow-hidden" style={{ height: '250px'}}>
                                                    <img src={data.image} alt="master team" className="position-absolute" style={{ height: '100%' , width: '100%'}}/>
                                                </div>
                                                <div className="master-image-data">
                                                    <h3 className="text-center text-white mb-3">{data.Name}</h3>
                                                    <h3 className="text-center text-white mb-2">{data.Major}</h3>
                                                    <p className="text-center">{data.Experience}</p>
                                                    <p className="text-center">{data.Email}</p>
                                                </div>
                                            </div>
                                        </div>
                                    ))
                        
                                ) : (
                                    <h3 classNameName='text-dark'>No Teams Data Found.</h3>
                                )
                            }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

  );
};