import {ServiceData} from '../Items/ServicesItems.jsx'
import { Link } from "react-router-dom";
export const AboutService = () => {
  return (
    <div className="AboutService-section bg-light py-4 py-lg-5">
        <div className="container">
            <div className="AboutService-container">
                <h2 className='h2 text-dark text-center mb-5'>Services We Provide</h2>
                <div className="row">
                    {ServiceData.slice(0, 8).map((data, index) => (
                        <div key={index} className="col-12 col-md-6 position-relative mb-4">
                            <div className="image-container overflow-hidden bg-dark rounded shadow" style={{height: '400px'}}>
                                <img src={data.image} height="100%" width="100%" className="w-100 h-100 m-auto d-block img-fluid" alt={data.title} loading="lazy"/>                            
                            </div>
                            <div className="card-data text-center position-absolute start-0 end-0 text-white">
                                <h2 className='h2'>{data.title}</h2>
                                <div className="AboutService-button-container border rounded m-auto p-1">
                                    <Link to="/services" className="AboutService-button d-block border text-white text-decoration-none py-1">View More</Link>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    </div>
  );
};
